export default {
  async selectClienteTelefono (Vue, idclienteTelefono, idsistema) {
    const tables = Vue.$offline.db.tables
    let selectBase
    let whereFilter = []
    if (idsistema) {
      selectBase = Vue.$offline.db
        .select()
        .from(tables.sistema_telefono)
        .innerJoin(
          tables.cliente_telefono,
          tables.sistema_telefono.idcliente_telefono.eq(tables.cliente_telefono.idcliente_telefono)
        )
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.idcliente_telefono.eq(idclienteTelefono),
          tables.cliente_telefono.estado.gt(0),
          tables.sistema_telefono.idsistema.eq(idsistema),
          tables.sistema_telefono.estado.gt(0),
        )
      )
    } else {
      selectBase = Vue.$offline.db
        .select()
        .from(tables.cliente_telefono)
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.cliente_telefono.idcliente_telefono.eq(idclienteTelefono),
          tables.cliente_telefono.estado.gt(0),
        )
      )
    }
    return (await selectBase
      .innerJoin(
        tables.cliente,
        tables.cliente_telefono.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.ttelefono,
        tables.cliente_telefono.idttelefono.eq(tables.ttelefono.idttelefono)
      )
      .where(...whereFilter)
      .exec())[0]
  },
}
