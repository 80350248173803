<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :es-contacto-sistema="formattedItem.esContactoSistema"
          />
        </template>
        <template
          slot="cardActionsLeft"
        >
          <v-btn
            v-if="formattedItem.cliente_telefono && formattedItem.cliente_telefono.telefono"
            icon
            color="primary"
            @click.stop="clickLlamar"
          >
            <v-icon>{{ $vuetify.icons.values.phone }}</v-icon>
          </v-btn>
          <v-btn
            v-if="formattedItem.cliente_telefono && formattedItem.cliente_telefono.email"
            icon
            color="primary"
            @click.stop="clickEnviarEmail"
          >
            <v-icon>{{ $vuetify.icons.values.email }}</v-icon>
          </v-btn>
        </template>
      </b10-view-summary>
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './ClienteTelefonoViewData'
import { nonEmpty } from '@/utils/templates'
import ExtraSubtitle from '../components/ClienteTelefonoExtraSubtitle'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.cliente_telefono.idcliente_telefono
        item.title = item.ttelefono.descripcion
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente_telefono.telefono}
          ${item.cliente_telefono.email}
        `)
        item.esContactoSistema = !!item.sistema_telefono?.idsistema_telefono
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    // routeQuery.idsistema: filtro por teléfonos del sistema (existe en sistema_telefono)
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = (
        this.item.dataset.cliente_telefono.nombre ||
        this.item.dataset.cliente_telefono.telefono ||
        this.item.dataset.cliente_telefono.email
      )
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = [
        { name: 'cliente_telefono.nombre', label: 'Nombre' },
        { name: 'ttelefono.descripcion', label: 'Tipo de contacto' },
        { name: 'cliente_telefono.email', label: 'Correo electrónico' },
        { name: 'cliente_telefono.telefono', label: 'Teléfono' },
        { name: 'cliente_telefono.horario', label: 'Horario' },
        {
          name: 'cliente_telefono.email_notifica_sat',
          label: 'Notificaciones de servicio técnico',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'cliente_telefono.email_notifica_comercial',
          label: 'Notificaciones comerciales',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'cliente_telefono.email_notifica_admon',
          label: 'Notificaciones de administración',
          filter: this.$options.filters.humanizeBoolean,
        },
        {
          name: 'sistema_telefono.es_responsable_propiedad',
          label: 'Es responsable de la propiedad',
          filter: this.$options.filters.humanizeBoolean,
          visible: !!this.routeQuery.idsistema,
        },
        {
          name: 'sistema_telefono.orden_cra', label: 'Orden CRA',
          visible: (
            this.$offline.db.isOfflinePk(this.routeParams.idcliente_telefono) &&
            !!this.routeQuery.idsistema
          ),
        },
        {
          name: 'sistema_telefono.codigo_cra',
          label: 'Código CRA',
          visible: (
            this.hasViewPerm(this.permissions.sistemas.verPersonasContactoCodigoCRA) &&
            !!this.routeQuery.idsistema &&
            this.$offline.db.isOfflinePk(this.routeParams.idcliente_telefono)
          ),
          password: true,
          clickable: true,
          icon: this.$vuetify.icons.values.show,
        },
        {
          name: 'sistema_telefono.codigo_coaccion_cra',
          label: 'Código coacción CRA',
          visible: (
            this.hasViewPerm(this.permissions.sistemas.verPersonasContactoCodigoCoaccionCRA) &&
            !!this.routeQuery.idsistema &&
            this.$offline.db.isOfflinePk(this.routeParams.idcliente_telefono)
          ),
          password: true,
          clickable: true,
          icon: this.$vuetify.icons.values.show,
        },
        {
          name: 'cliente_telefono.para_todos_los_sistemas',
          label: 'Disponible en todos los sistemas',
          filter: this.$options.filters.humanizeBoolean,
        },
        { name: 'cliente_telefono.observaciones', label: 'Observaciones' },
      ]
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectClienteTelefono(this, this.routeParams.idcliente_telefono, this.routeQuery.idsistema)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    clickEdit () {
      this.$appRouter.push({
        name: 'offline__cliente-telefono-edit',
        params: {
          idcliente: this.item.dataset.cliente.idcliente,
          idcliente_telefono: this.routeParams.idcliente_telefono,
        },
        query: {
          idsistema: this.routeQuery.idsistema,
        },
      })
    },
    clickLlamar () {
      window.open(`tel:${this.item.dataset.cliente_telefono.telefono}`, '_blank')
    },
    clickEnviarEmail () {
      window.open(`mailto:${this.item.dataset.cliente_telefono.email}`, '_blank')
    },
    async clickSummaryRow (row) {
      if (row.icon === this.$vuetify.icons.values.show) {
        this.setMoreInfoRowValue(row.name, 'password', false)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.hide)
      } else {
        this.setMoreInfoRowValue(row.name, 'password', true)
        this.setMoreInfoRowValue(row.name, 'icon', this.$vuetify.icons.values.show)
      }
    },
  }
}
</script>
